import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { hasTokenInfo, removeTokenInfo, setTriggerEvent, setTriggerPvid } from '@/utils/token';
import { LoginBtnReportParams, StudentInfo, User } from '@/request/interface/login';
import { getStudentList } from '@/request/login';
import { getLoginJumpUrl, getStuCenterUrl, updateIntercomInfo } from '@/utils/utils';
import cacheStorage from '@/utils/cacheStorage';
import { getLoginReportL, TOKEN } from '@/utils/constants';
import { getBasicInfo, traceEvent } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import { GlobalLoginContext, ILogin } from '@/context/GlobalLoginContest';
import useUserStore from '@/store/useUserStore';
import { mergeQueryParams } from '@/utils/url';

interface IProps {
  children: React.ReactNode;
}

export const GlobalLoginProvider: React.FC<IProps> = ({ children }) => {
  const [loginPCVisible, setLoginPCVisible] = useState<boolean>(false);
  const [loginMobileVisible, setLoginMobileVisible] = useState<boolean>(false);
  const [loginPCPhoneVisible, setLoginPCPhoneVisible] = useState<boolean>(false);
  const [logoutDrawerVisible, setLogoutDrawerVisible] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(hasTokenInfo());
  const [student, setStudent] = useState<StudentInfo>();
  const loginParams = useRef<ILogin | undefined>();
  const setUser = useUserStore((state) => state.setUser);

  const router = useRouter();

  const handleLogin = useCallback((params?: ILogin | undefined) => {
    const data: ILogin | undefined = params;
    if (params?.subject) {
      data!.subject = params.subject?.toUpperCase() as 'CHINESE' | 'ENGLISH';
    }
    if (!params || !params?.source) {
      data!.source = 'OFFICIAL_WEBSITE'; // 新官网除了登录按钮和下载页的登录进线为学员中心，其它地方都进线为增长。（如果不传，默认为学员中心，所以非学员中心的场景下需要强传）
    }
    loginParams.current = { ...params, ...data };

    /* 如果仅需要手机登录，则弹出手机登录弹窗,移动端暂时不需要手机登录弹窗 */
    if (params?.isPhoneLogin && (window.innerWidth > 720 || params.forceOpenPCLogin)) {
      setLoginPCPhoneVisible(true);
      return;
    }

    if (window.innerWidth <= 1023) {
      setLoginMobileVisible(true);
    } else {
      setLoginPCVisible(true);
    }
  }, []);

  const getStudents = async (_loginBtnReportParams?: LoginBtnReportParams) => {
    if (!hasTokenInfo()) {
      setIsLogin(false);
      updateIntercomInfo();
      return;
    }
    setIsLogin(true);
    const results = await getStudentList();
    setUser(results?.[0]);
    const curStudent = results?.[0] || { fullName: 'Student' };
    setStudent(curStudent);
    updateIntercomInfo(curStudent);
  };

  useEffect(() => {
    const href = window?.location?.href;
    if (href?.includes('return_url') && !href?.includes('student-center-login') && !isLogin) {
      handleLogin({ source: 'STU_CENTER' });
    }
    getStudents();
  }, []);

  // const memorizedHandleAppointment = useCallback(() => {
  //   router.push('/independent-appointment');
  // }, [router]);

  const handleLogout = useCallback(() => {
    removeTokenInfo();
    window.location.reload();
  }, []);

  const jumpReturnUrl = () => {
    if (!window.location.search.includes('return_url')) {
      return;
    }
    traceEvent({
      data: {
        _event: 'O_OfficialWebGoCenter',
        account_id: student?.uuid,
        payload: {
          l: getLoginReportL(),
          url: window.location.href,
          page_name: router.pathname,
          button_name: '进入学员中心',
          re_event: 'O_LoginButtonClick',
        },
      },
    });
    window.location.href = getLoginJumpUrl();
  };

  const handleLoginFinish = useCallback(
    async (notToStuCenter?: boolean, loginBtnReportParams?: LoginBtnReportParams, loginUser?: User) => {
      await getStudents(loginBtnReportParams);
      // 如果是学习园地页面，则不进行跳转
      if (window.location.href.includes('/resources/')) {
        return;
      }
      if (loginUser) {
        // 如果是 pc 端，走新的自主化流程，判断是走约课流利还是进入学员中心
        // login 代表是老用户，直接进学员中心或者 return_url
        // active 代表是家庭内的用户，同 login 的跳转逻辑
        // 如果区号为+86, 直接跳转到学员中心
        const { phone } = loginUser;
        if (phone && phone.split(' ')[0] === '+86') {
          window.location.href = getStuCenterUrl();
        } else if (
          loginUser?.accountData?.loginOrRegister &&
          ['login', 'active'].includes(loginUser?.accountData?.loginOrRegister)
        ) {
          jumpReturnUrl();
        } else {
          let bookUrl = `${window.location.href.includes('/zh/') ? '/zh' : ''}/independent-appointment`;
          if (loginParams?.current?.subject) {
            bookUrl = `${bookUrl}?subject=${loginParams.current?.subject?.toLowerCase()}`;
          }
          if (loginParams?.current?.source && bookUrl.includes('?subject=')) {
            bookUrl = `${bookUrl}&source=${loginParams.current?.source?.toUpperCase()}`;
          } else {
            bookUrl = `${bookUrl}?source=${loginParams.current?.source?.toUpperCase()}`;
          }

          window.location.href = mergeQueryParams(bookUrl);
        }
        // 如果存在token，且传递参数需要跳转到学生中心，则跳转学员中心
      } else if (cacheStorage.getItem(TOKEN) && !notToStuCenter) {
        jumpReturnUrl();
      }
    },
    [],
  );

  const handleGoToStuCenter = useCallback(
    (openInCurrentTab?: boolean) => {
      setTriggerPvid(((getBasicInfo() as any).pvid as string) || '');
      setTriggerEvent('O_LoginButtonClick');
      traceEvent({
        data: {
          _event: 'O_OfficialWebGoCenter',
          account_id: student?.uuid,
          payload: {
            l: getLoginReportL(),
            url: window.location.href,
            page_name: router.pathname,
            button_name: '进入学员中心',
            re_event: 'O_LoginButtonClick',
          },
        },
      });
      const url = getStuCenterUrl();
      if (openInCurrentTab) {
        window.location.href = url;
        return;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    [router.pathname, student?.uuid],
  );

  const handleCustomLoginFinish = useCallback(async () => {
    await getStudents();
    loginParams?.current?.loginFinishCallback?.();
  }, []);

  const value = useMemo(
    () => ({
      isLogin,
      handleLogin,
      loginPCVisible,
      setLoginPCVisible,
      loginMobileVisible,
      setLoginMobileVisible,
      logoutDrawerVisible,
      setLogoutDrawerVisible,
      handleLogout,
      student,
      getStudents,
      handleLoginFinish,
      loginParams: loginParams.current,
      handleGoToStuCenter,
      loginPCPhoneVisible,
      setLoginPCPhoneVisible,
      handleCustomLoginFinish,
    }),
    [
      isLogin,
      handleLogin,
      loginPCVisible,
      setLoginPCVisible,
      loginMobileVisible,
      setLoginMobileVisible,
      logoutDrawerVisible,
      setLogoutDrawerVisible,
      handleLogout,
      student,
      getStudents,
      handleLoginFinish,
      loginParams,
      handleGoToStuCenter,
      loginPCPhoneVisible,
      setLoginPCPhoneVisible,
      handleCustomLoginFinish,
    ],
  );
  return <GlobalLoginContext.Provider value={value}>{children}</GlobalLoginContext.Provider>;
};
